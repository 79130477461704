<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">保护区划界线描述与管理规定</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <div
                    v-for="(item, index) in formdata.dataDic.HCQYCQLIST"
                    :key="'hcqycq' + index"
                    class="content_box"
                >
                    <div class="top_content">
                        <span class="menu_title">缓冲区与遗产区界线</span>
                    </div>
                    <div>
                        <el-form-item
                            label="缓冲区名称"
                            :prop="'HCQYCQLIST.' + index + '.hcq.hcqmc'"
                        >
                            <el-input
                                v-model="item.hcq.hcqmc"
                                :maxlength="50"
                                :disabled="true"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="缓冲区面积（公顷）"
                            :prop="'HCQYCQLIST.' + index + '.hcq.hcqmj'"
                        >
                            <el-input
                                v-model="item.hcq.hcqmj"
                                :maxlength="50"
                                :disabled="true"
                                @input="
                                    item.hcq.hcqmj = (item.hcq.hcqmj.match(
                                        /\d+(\.\d{0,})?/
                                    ) || [''])[0]
                                "
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item
                            label="缓冲区界线"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="item.hcq.hcqjx"
                                type="textarea"
                                :autosize="{ minRows: 6 }"
                                maxlength="500"
                                show-word-limit
                                :disabled="true"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="缓冲区管理规定"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="item.hcq.hcqglgd"
                                type="textarea"
                                :autosize="{ minRows: 6 }"
                                maxlength="500"
                                show-word-limit
                                :disabled="true"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <div
                        v-for="(item1, index1) in item.ycqlist"
                        :key="index1"
                        class="ycq_content"
                    >
                        <el-form-item
                            label="遗产区名称"
                            :prop="
                                'HCQYCQLIST.' +
                                index +
                                '.ycqlist.' +
                                index1 +
                                '.ycqmc'
                            "
                        >
                            <el-input
                                v-model="item1.ycqmc"
                                :maxlength="50"
                                :disabled="true"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="遗产区面积（公顷）"
                            :prop="
                                'HCQYCQLIST.' +
                                index +
                                '.ycqlist.' +
                                index1 +
                                '.ycqmj'
                            "
                        >
                            <el-input
                                v-model="item1.ycqmj"
                                :maxlength="50"
                                :disabled="true"
                                @input="
                                    item1.ycqmj = (item1.ycqmj.match(
                                        /\d+(\.\d{0,})?/
                                    ) || [''])[0]
                                "
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="遗产区界线"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="item1.ycqjx"
                                type="textarea"
                                :autosize="{ minRows: 6 }"
                                maxlength="500"
                                show-word-limit
                                :disabled="true"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="遗产区管理规定"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="item1.ycqglgd"
                                type="textarea"
                                :autosize="{ minRows: 6 }"
                                maxlength="500"
                                show-word-limit
                                :disabled="true"
                            ></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div
                    v-for="(item, index) in formdata.dataDic.JKDDLIST"
                    :key="'jkddbhfw' + index"
                    class="content_box"
                >
                    <div class="top_content">
                        <span class="menu_title"
                            >建设控制地带与保护范围界线</span
                        >
                    </div>
                    <div>
                        <el-form-item
                            label="是否与缓冲区一致"
                            :prop="'JKDDLIST.' + index + '.jkdd.sfyhcqyz'"
                        >
                            <el-radio-group
                                v-model="item.jkdd.sfyhcqyz"
                                :disabled="true"
                            >
                                <el-radio label="1">是</el-radio>
                                <el-radio label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item
                            v-if="item.jkdd.sfyhcqyz === '1'"
                            label="对应缓冲区名称"
                            :prop="'JKDDLIST.' + index + '.jkdd.dyhcqmc'"
                        >
                            <el-select
                                v-model="item.jkdd.dyhcqmc"
                                clearable
                                placeholder="请选择对应缓冲区名称"
                                @change="
                                    (val) => {
                                        chooseHcqmc(val, index);
                                    }
                                "
                                @visible-change="
                                    (val) => {
                                        visibleChange(val, 'changeHcqmc');
                                    }
                                "
                                :disabled="true"
                            >
                                <el-option
                                    v-for="(ele, index) in hcqList"
                                    :key="index"
                                    :label="ele.name"
                                    :value="ele.name"
                                    :disabled="true"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            v-if="item.jkdd.sfyhcqyz === '0'"
                            label="国保单位"
                            :prop="'JKDDLIST.' + index + '.jkdd.dyhcqmc'"
                        >
                            <el-select
                                v-model="item.jkdd.gbdwid"
                                clearable
                                placeholder="请选择对应国保单位"
                                :disabled="true"
                            >
                                <el-option
                                    v-for="(ele, index) in gbdwList"
                                    :key="index"
                                    :label="ele.name"
                                    :value="ele.id"
                                    :disabled="true"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="建设控制地带名称"
                            :prop="'JKDDLIST.' + index + '.jkdd.jskzddmc'"
                        >
                            <el-input
                                v-model="item.jkdd.jskzddmc"
                                :maxlength="50"
                                :disabled="true"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="建设控制地带面积（公顷）"
                            :prop="'JKDDLIST.' + index + '.jkdd.jskzddmj'"
                        >
                            <el-input
                                v-model="item.jkdd.jskzddmj"
                                :maxlength="50"
                                :disabled="true"
                                @input="
                                    item.jkdd.jskzddmj =
                                        (item.jkdd.jskzddmj.match(
                                            /\d+(\.\d{0,})?/
                                        ) || [''])[0]
                                "
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item
                            label="建设控制地带界线"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="item.jkdd.jskzddjx"
                                type="textarea"
                                :autosize="{ minRows: 6 }"
                                maxlength="500"
                                show-word-limit
                                :disabled="true"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="建设控制地带管理规定"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="item.jkdd.jskzddglgd"
                                type="textarea"
                                :autosize="{ minRows: 6 }"
                                maxlength="500"
                                show-word-limit
                                :disabled="true"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <div
                        v-for="(item1, index1) in item.bhfwlist"
                        :key="index1"
                        class="ycq_content"
                    >
                        <el-form-item
                            label="是否与遗产区一致"
                            :prop="
                                'JKDDLIST.' +
                                index +
                                '.bhfwlist.' +
                                index1 +
                                '.sfyycqyz'
                            "
                        >
                            <el-radio-group
                                v-model="item1.sfyycqyz"
                                :disabled="true"
                            >
                                <el-radio label="1">是</el-radio>
                                <el-radio label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item
                            v-if="item1.sfyycqyz === '1'"
                            label="对应遗产区名称"
                            :prop="
                                'JKDDLIST.' +
                                index +
                                '.bhfwlist.' +
                                index1 +
                                '.dyycqmc'
                            "
                        >
                            <el-select
                                v-model="item1.dyycqmc"
                                clearable
                                placeholder="请选择对应遗产区名称"
                                :disabled="true"
                            >
                                <el-option
                                    v-for="(ele, index) in ycqList"
                                    :key="index"
                                    :label="ele.name"
                                    :value="ele.name"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="保护范围名称"
                            :prop="
                                'JKDDLIST.' +
                                index +
                                '.bhfwlist.' +
                                index1 +
                                '.bhfwmc'
                            "
                        >
                            <el-input
                                v-model="item1.bhfwmc"
                                :maxlength="50"
                                :disabled="true"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="保护范围面积（公顷）"
                            :prop="
                                'JKDDLIST.' +
                                index +
                                '.bhfwlist.' +
                                index1 +
                                '.bhfwmj'
                            "
                        >
                            <el-input
                                v-model="item1.bhfwmj"
                                :maxlength="50"
                                :disabled="true"
                                @input="
                                    item1.bhfwmj = (item1.bhfwmj.match(
                                        /\d+(\.\d{0,})?/
                                    ) || [''])[0]
                                "
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="保护范围界线"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="item1.bhfwjx"
                                type="textarea"
                                :autosize="{ minRows: 6 }"
                                maxlength="500"
                                show-word-limit
                                :disabled="true"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="保护范围管理规定"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="item1.bhfwglgd"
                                type="textarea"
                                :autosize="{ minRows: 6 }"
                                maxlength="500"
                                show-word-limit
                                :disabled="true"
                            ></el-input>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "jghjjcsj",
    mixins: [infoMixin],
    data() {
        return {
            saveButton: true,
            formdata: {
                itemCode: "90102",
                dataDic: {
                    HCQYCQLIST: [
                        {
                            hcq: {
                                id: "",
                                hcqmc: "",
                                hcqmj: null,
                                hcqjx: "",
                                hcqglgd: "",
                                sort: null,
                            },
                            ycqlist: [
                                {
                                    id: "",
                                    ycqmc: "",
                                    ycqmj: null,
                                    ycqjx: "",
                                    ycqglgd: "",
                                    sort: null,
                                },
                            ],
                        },
                    ],
                    JKDDLIST: [
                        {
                            jkdd: {
                                id: "",
                                jskzddmc: "",
                                sfyhcqyz: "",
                                dyhcqid: "",
                                dyhcqmc: "",
                                gbdwmc: "",
                                gbdwid: "",
                                jskzddmj: "",
                                jskzddjx: "",
                                jskzddglgd: "",
                                sort: null,
                            },
                            bhfwlist: [
                                {
                                    id: "",
                                    bhfwmc: "",
                                    sfyycqyz: "",
                                    dyycqid: "",
                                    dyycqmc: "",
                                    bhfwmj: "",
                                    bhfwjx: "",
                                    bhfwglgd: "",
                                    sort: null,
                                },
                            ],
                        },
                    ],
                    mainlist: [
                        {
                            id: "",
                            GLYCBTID: "11030",
                            BBSX: "",
                            shzt: "",
                        },
                    ],
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "21",
                userName: "",
                userId: "",
            },
            rules: {
                // FSSJ: [
                //     { required: true, message: '请选择发生时间', trigger: 'change' }
                // ],
                // SS: [
                //     { required: true, message: '请填写损失', trigger: 'blur' }
                // ],
                // CLQK: [
                //     { required: true, message: '请填写处理情况', trigger: 'blur' }
                // ]
            },
            SGLXList: [],
            showPickup: false,
            jwd: "",
            jkddData: {
                jkdd: {
                    id: "",
                    jskzddmc: "",
                    sfyhcqyz: "",
                    dyhcqid: "",
                    dyhcqmc: "",
                    gbdwmc: "",
                    gbdwid: "",
                    jskzddmj: "",
                    jskzddjx: "",
                    jskzddglgd: "",
                    sort: null,
                },
                bhfwlist: [
                    {
                        id: "",
                        bhfwmc: "",
                        sfyycqyz: "",
                        dyycqid: "",
                        dyycqmc: "",
                        bhfwmj: "",
                        bhfwjx: "",
                        bhfwglgd: "",
                        sort: null,
                    },
                ],
            },
            hcqycqData: {
                hcq: {
                    id: "",
                    hcqmc: "",
                    hcqmj: null,
                    hcqjx: "",
                    hcqglgd: "",
                    sort: null,
                },
                ycqlist: [
                    {
                        id: "",
                        ycqmc: "",
                        ycqmj: null,
                        ycqjx: "",
                        ycqglgd: "",
                        sort: null,
                    },
                ],
            },
            HCQYCQLIST: [],
            JKDDLIST: [],
            hcqList: [],
            ycqList: [],
            gbdwList: [],
        };
    },
    watch: {
        "formdata.dataDic": {
            deep: true,
            handler: function () {},
        },
    },
    mounted() {
        this.GetEnum();
        // this.HCQYCQLIST.push(JSON.parse(JSON.stringify(this.hcqycqData)));
        // this.JKDDLIST.push(JSON.parse(JSON.stringify(this.jkddData)));
        // this.formdata.dataDic.HCQYCQLIST = this.HCQYCQLIST;
        // this.formdata.dataDic.JKDDLIST = this.JKDDLIST;
        // debugger
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID"]),
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "1303",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "JB") {
                    this.SGLXList = v.dataItems;
                }
            });
        },
        clickPoint() {
            let value = this.jwd.split(",");
            this.formdata.dataDic.JD = value[1];
            this.formdata.dataDic.WD = value[0];
        },
    },
};
</script>

<style>
.content_box {
    border: 1px solid#C0C4CC;
    padding: 20px;
    border-radius: 10px;
    margin-top: 15px;
}
.content_box > div:not(.top_content) {
    background-color: white;
    margin-top: 10px;
    padding-right: 100px;
}
.top_content {
    height: 40px;
}
.top_content .menu_title {
    line-height: 40px;
}
.ycq_content {
    position: relative;
}
.ycq_Button {
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translate(50%, -50%);
    cursor: pointer;
}
.ycq_Button i {
    font-size: 25px;
}
.ycq_Button .el-icon-circle-plus {
    color: #28cdd9;
}
.ycq_Button .el-icon-remove {
    color: #ff9066;
}
</style>
