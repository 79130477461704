var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basic_data_edit"},[_c('div',{staticClass:"data_content scrollbar"},[_c('div',{staticClass:"botton_box"},[_c('span',{staticClass:"menu_title"},[_vm._v("保护区划界线描述与管理规定")]),_c('div',{staticClass:"button_list"},[_c('sn-button',{class:{ showButton: !_vm.saveButton },attrs:{"snButton":_vm.snButton},on:{"handleChange":_vm.handleChange}})],1)]),_c('el-form',{ref:"formdata",staticClass:"demo-form-inline form_row",attrs:{"inline":true,"model":_vm.formdata.dataDic,"rules":_vm.rules,"size":"small","label-width":"200px"}},[_vm._l((_vm.formdata.dataDic.HCQYCQLIST),function(item,index){return _c('div',{key:'hcqycq' + index,staticClass:"content_box"},[_c('div',{staticClass:"top_content"},[_c('span',{staticClass:"menu_title"},[_vm._v("缓冲区与遗产区界线")])]),_c('div',[_c('el-form-item',{attrs:{"label":"缓冲区名称","prop":'HCQYCQLIST.' + index + '.hcq.hcqmc'}},[_c('el-input',{attrs:{"maxlength":50,"disabled":true},model:{value:(item.hcq.hcqmc),callback:function ($$v) {_vm.$set(item.hcq, "hcqmc", $$v)},expression:"item.hcq.hcqmc"}})],1),_c('el-form-item',{attrs:{"label":"缓冲区面积（公顷）","prop":'HCQYCQLIST.' + index + '.hcq.hcqmj'}},[_c('el-input',{attrs:{"maxlength":50,"disabled":true},on:{"input":function($event){item.hcq.hcqmj = (item.hcq.hcqmj.match(
                                    /\d+(\.\d{0,})?/
                                ) || [''])[0]}},model:{value:(item.hcq.hcqmj),callback:function ($$v) {_vm.$set(item.hcq, "hcqmj", $$v)},expression:"item.hcq.hcqmj"}})],1),_c('el-form-item',{staticClass:"from-item-block",attrs:{"label":"缓冲区界线"}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 6 },"maxlength":"500","show-word-limit":"","disabled":true},model:{value:(item.hcq.hcqjx),callback:function ($$v) {_vm.$set(item.hcq, "hcqjx", $$v)},expression:"item.hcq.hcqjx"}})],1),_c('el-form-item',{staticClass:"from-item-block",attrs:{"label":"缓冲区管理规定"}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 6 },"maxlength":"500","show-word-limit":"","disabled":true},model:{value:(item.hcq.hcqglgd),callback:function ($$v) {_vm.$set(item.hcq, "hcqglgd", $$v)},expression:"item.hcq.hcqglgd"}})],1)],1),_vm._l((item.ycqlist),function(item1,index1){return _c('div',{key:index1,staticClass:"ycq_content"},[_c('el-form-item',{attrs:{"label":"遗产区名称","prop":'HCQYCQLIST.' +
                            index +
                            '.ycqlist.' +
                            index1 +
                            '.ycqmc'}},[_c('el-input',{attrs:{"maxlength":50,"disabled":true},model:{value:(item1.ycqmc),callback:function ($$v) {_vm.$set(item1, "ycqmc", $$v)},expression:"item1.ycqmc"}})],1),_c('el-form-item',{attrs:{"label":"遗产区面积（公顷）","prop":'HCQYCQLIST.' +
                            index +
                            '.ycqlist.' +
                            index1 +
                            '.ycqmj'}},[_c('el-input',{attrs:{"maxlength":50,"disabled":true},on:{"input":function($event){item1.ycqmj = (item1.ycqmj.match(
                                    /\d+(\.\d{0,})?/
                                ) || [''])[0]}},model:{value:(item1.ycqmj),callback:function ($$v) {_vm.$set(item1, "ycqmj", $$v)},expression:"item1.ycqmj"}})],1),_c('el-form-item',{staticClass:"from-item-block",attrs:{"label":"遗产区界线"}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 6 },"maxlength":"500","show-word-limit":"","disabled":true},model:{value:(item1.ycqjx),callback:function ($$v) {_vm.$set(item1, "ycqjx", $$v)},expression:"item1.ycqjx"}})],1),_c('el-form-item',{staticClass:"from-item-block",attrs:{"label":"遗产区管理规定"}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 6 },"maxlength":"500","show-word-limit":"","disabled":true},model:{value:(item1.ycqglgd),callback:function ($$v) {_vm.$set(item1, "ycqglgd", $$v)},expression:"item1.ycqglgd"}})],1)],1)})],2)}),_vm._l((_vm.formdata.dataDic.JKDDLIST),function(item,index){return _c('div',{key:'jkddbhfw' + index,staticClass:"content_box"},[_c('div',{staticClass:"top_content"},[_c('span',{staticClass:"menu_title"},[_vm._v("建设控制地带与保护范围界线")])]),_c('div',[_c('el-form-item',{attrs:{"label":"是否与缓冲区一致","prop":'JKDDLIST.' + index + '.jkdd.sfyhcqyz'}},[_c('el-radio-group',{attrs:{"disabled":true},model:{value:(item.jkdd.sfyhcqyz),callback:function ($$v) {_vm.$set(item.jkdd, "sfyhcqyz", $$v)},expression:"item.jkdd.sfyhcqyz"}},[_c('el-radio',{attrs:{"label":"1"}},[_vm._v("是")]),_c('el-radio',{attrs:{"label":"0"}},[_vm._v("否")])],1)],1),(item.jkdd.sfyhcqyz === '1')?_c('el-form-item',{attrs:{"label":"对应缓冲区名称","prop":'JKDDLIST.' + index + '.jkdd.dyhcqmc'}},[_c('el-select',{attrs:{"clearable":"","placeholder":"请选择对应缓冲区名称","disabled":true},on:{"change":function (val) {
                                    _vm.chooseHcqmc(val, index);
                                },"visible-change":function (val) {
                                    _vm.visibleChange(val, 'changeHcqmc');
                                }},model:{value:(item.jkdd.dyhcqmc),callback:function ($$v) {_vm.$set(item.jkdd, "dyhcqmc", $$v)},expression:"item.jkdd.dyhcqmc"}},_vm._l((_vm.hcqList),function(ele,index){return _c('el-option',{key:index,attrs:{"label":ele.name,"value":ele.name,"disabled":true}})}),1)],1):_vm._e(),(item.jkdd.sfyhcqyz === '0')?_c('el-form-item',{attrs:{"label":"国保单位","prop":'JKDDLIST.' + index + '.jkdd.dyhcqmc'}},[_c('el-select',{attrs:{"clearable":"","placeholder":"请选择对应国保单位","disabled":true},model:{value:(item.jkdd.gbdwid),callback:function ($$v) {_vm.$set(item.jkdd, "gbdwid", $$v)},expression:"item.jkdd.gbdwid"}},_vm._l((_vm.gbdwList),function(ele,index){return _c('el-option',{key:index,attrs:{"label":ele.name,"value":ele.id,"disabled":true}})}),1)],1):_vm._e(),_c('el-form-item',{attrs:{"label":"建设控制地带名称","prop":'JKDDLIST.' + index + '.jkdd.jskzddmc'}},[_c('el-input',{attrs:{"maxlength":50,"disabled":true},model:{value:(item.jkdd.jskzddmc),callback:function ($$v) {_vm.$set(item.jkdd, "jskzddmc", $$v)},expression:"item.jkdd.jskzddmc"}})],1),_c('el-form-item',{attrs:{"label":"建设控制地带面积（公顷）","prop":'JKDDLIST.' + index + '.jkdd.jskzddmj'}},[_c('el-input',{attrs:{"maxlength":50,"disabled":true},on:{"input":function($event){item.jkdd.jskzddmj =
                                    (item.jkdd.jskzddmj.match(
                                        /\d+(\.\d{0,})?/
                                    ) || [''])[0]}},model:{value:(item.jkdd.jskzddmj),callback:function ($$v) {_vm.$set(item.jkdd, "jskzddmj", $$v)},expression:"item.jkdd.jskzddmj"}})],1),_c('el-form-item',{staticClass:"from-item-block",attrs:{"label":"建设控制地带界线"}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 6 },"maxlength":"500","show-word-limit":"","disabled":true},model:{value:(item.jkdd.jskzddjx),callback:function ($$v) {_vm.$set(item.jkdd, "jskzddjx", $$v)},expression:"item.jkdd.jskzddjx"}})],1),_c('el-form-item',{staticClass:"from-item-block",attrs:{"label":"建设控制地带管理规定"}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 6 },"maxlength":"500","show-word-limit":"","disabled":true},model:{value:(item.jkdd.jskzddglgd),callback:function ($$v) {_vm.$set(item.jkdd, "jskzddglgd", $$v)},expression:"item.jkdd.jskzddglgd"}})],1)],1),_vm._l((item.bhfwlist),function(item1,index1){return _c('div',{key:index1,staticClass:"ycq_content"},[_c('el-form-item',{attrs:{"label":"是否与遗产区一致","prop":'JKDDLIST.' +
                            index +
                            '.bhfwlist.' +
                            index1 +
                            '.sfyycqyz'}},[_c('el-radio-group',{attrs:{"disabled":true},model:{value:(item1.sfyycqyz),callback:function ($$v) {_vm.$set(item1, "sfyycqyz", $$v)},expression:"item1.sfyycqyz"}},[_c('el-radio',{attrs:{"label":"1"}},[_vm._v("是")]),_c('el-radio',{attrs:{"label":"0"}},[_vm._v("否")])],1)],1),(item1.sfyycqyz === '1')?_c('el-form-item',{attrs:{"label":"对应遗产区名称","prop":'JKDDLIST.' +
                            index +
                            '.bhfwlist.' +
                            index1 +
                            '.dyycqmc'}},[_c('el-select',{attrs:{"clearable":"","placeholder":"请选择对应遗产区名称","disabled":true},model:{value:(item1.dyycqmc),callback:function ($$v) {_vm.$set(item1, "dyycqmc", $$v)},expression:"item1.dyycqmc"}},_vm._l((_vm.ycqList),function(ele,index){return _c('el-option',{key:index,attrs:{"label":ele.name,"value":ele.name}})}),1)],1):_vm._e(),_c('el-form-item',{attrs:{"label":"保护范围名称","prop":'JKDDLIST.' +
                            index +
                            '.bhfwlist.' +
                            index1 +
                            '.bhfwmc'}},[_c('el-input',{attrs:{"maxlength":50,"disabled":true},model:{value:(item1.bhfwmc),callback:function ($$v) {_vm.$set(item1, "bhfwmc", $$v)},expression:"item1.bhfwmc"}})],1),_c('el-form-item',{attrs:{"label":"保护范围面积（公顷）","prop":'JKDDLIST.' +
                            index +
                            '.bhfwlist.' +
                            index1 +
                            '.bhfwmj'}},[_c('el-input',{attrs:{"maxlength":50,"disabled":true},on:{"input":function($event){item1.bhfwmj = (item1.bhfwmj.match(
                                    /\d+(\.\d{0,})?/
                                ) || [''])[0]}},model:{value:(item1.bhfwmj),callback:function ($$v) {_vm.$set(item1, "bhfwmj", $$v)},expression:"item1.bhfwmj"}})],1),_c('el-form-item',{staticClass:"from-item-block",attrs:{"label":"保护范围界线"}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 6 },"maxlength":"500","show-word-limit":"","disabled":true},model:{value:(item1.bhfwjx),callback:function ($$v) {_vm.$set(item1, "bhfwjx", $$v)},expression:"item1.bhfwjx"}})],1),_c('el-form-item',{staticClass:"from-item-block",attrs:{"label":"保护范围管理规定"}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 6 },"maxlength":"500","show-word-limit":"","disabled":true},model:{value:(item1.bhfwglgd),callback:function ($$v) {_vm.$set(item1, "bhfwglgd", $$v)},expression:"item1.bhfwglgd"}})],1)],1)})],2)})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }